<template>
  <div class="UserProfileNavigation column">
    <div class="row row--align-center row--justify-between">
      <div class="row row--align-center row--width-auto">
        <!-- Debtor name should be uppercase'd -->
        <!-- https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1639 -->
        <label
          :class="['fs-22 fw-med mr-8', {
            'uppercase': pagePOV === 'debtor',
          }]"
          data-cy="user-profile-nav-user-name"
        >
          {{ filterAlgoliaDebtorName(user.shortened_name || user.name) }}
        </label>
        <i
          :class="['fa fa-oval fa-10 mr-8', {
            'fc-green': user.status === 'active',
            'fc-red': user.status === 'inactive',
            'fc-yellow': user.status !== 'active' && user.status !== 'inactive'
          }]"
        />
      </div>
      <!-- Client only -->
      <div
        v-if="user.mc"
        class="row row--align-center row--width-auto"
      >
        <label class="fc-light fs-13 mr-6">Balance:</label>
        <label
          :class="['UserProfileNavigation__client-balance-flag fc-white fs-16', {
            'UserProfileNavigation__client-balance-flag--bg-light': clientBalance === 0,
            'UserProfileNavigation__client-balance-flag--bg-green': clientBalance > 0,
            'UserProfileNavigation__client-balance-flag--bg-red': clientBalance < 0,
          }]"
          data-cy="user-profile-navigation-balance"
        >
          {{
            Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
              .format(clientBalance / 100)
          }}
        </label>
      </div>
      <!-- Debtor only -->
      <div
        v-if="user.hasOwnProperty('broker')"
        class="row row--align-center row--width-auto"
      >
        <base-debtor-rating
          :rating="getDisplayRating(user.rating, user.external_rating)"
          :size="'large'"
        />
      </div>
    </div>
    <div class="column">
      <label
        v-if="user.mc || user.broker"
        class="fc-light fs-15 uppercase"
      >
        MC: {{ user.mc || user.broker.mc }}
      </label>
      <label
        v-if="user.phone"
        class="fc-light fs-15"
      >
        Phone: {{ formatPhoneNumber(user.phone) }}
      </label>
    </div>

    <div
      v-if="!userIsClient"
      class="UserProfileNavigation__nav-items row mt-30"
    >
      <router-link
        v-for="(route, index) in routes"
        :key="index"
        :to="route.to"
        active-class="UserProfileNavigation__link--active fc-dark fw-med"
        class="UserProfileNavigation__link fc-middle fs-15 mr-30 capitalize"
        :data-cy="`user-profile-nav-${route.text}-tab`"
        tag="a"
      >
        {{ route.text }}
      </router-link>
    </div>
  </div>
</template>

<script>
// Helpers
import {
  formatPhoneNumber,
  sanitizeAlgoliaNames
} from '../utils/helpers'
// Components
import BaseDebtorRating from './base-debtor-rating.vue'

export default {
  name: 'UserProfileNavigation',

  components: {
    BaseDebtorRating,
  },

  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    pagePOV: {
      type: String,
      required: true,
    },
    routes: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    userIsClient: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    clientBalance () {
      return this.$store.getters.client.balance
    },
  },

  methods: {
    getDisplayRating (rating, externalRating) {
      // Exists in case Bobtail wants to personally rate a debtor as something different than the
      // uploaded debtor spreadsheet, whichn is not overwritten when we upload a debtor sheet
      // The Bobtail rating is `rating`
      if (rating === 'F' || externalRating === '--') {
        return rating
      }
      return externalRating
    },

    // Remove <em></em> from debtor name
    // https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1694
    filterAlgoliaDebtorName (name) {
      return sanitizeAlgoliaNames(name)
    },

    formatPhoneNumber,
  },
}
</script>

<style lang="sass">
@import '../styles/user-profile-navigation'
</style>