var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "UserProfileNavigation column" }, [
    _c("div", { staticClass: "row row--align-center row--justify-between" }, [
      _c("div", { staticClass: "row row--align-center row--width-auto" }, [
        _c(
          "label",
          {
            class: [
              "fs-22 fw-med mr-8",
              {
                uppercase: _vm.pagePOV === "debtor"
              }
            ],
            attrs: { "data-cy": "user-profile-nav-user-name" }
          },
          [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.filterAlgoliaDebtorName(
                    _vm.user.shortened_name || _vm.user.name
                  )
                ) +
                "\n      "
            )
          ]
        ),
        _c("i", {
          class: [
            "fa fa-oval fa-10 mr-8",
            {
              "fc-green": _vm.user.status === "active",
              "fc-red": _vm.user.status === "inactive",
              "fc-yellow":
                _vm.user.status !== "active" && _vm.user.status !== "inactive"
            }
          ]
        })
      ]),
      _vm.user.mc
        ? _c("div", { staticClass: "row row--align-center row--width-auto" }, [
            _c("label", { staticClass: "fc-light fs-13 mr-6" }, [
              _vm._v("Balance:")
            ]),
            _c(
              "label",
              {
                class: [
                  "UserProfileNavigation__client-balance-flag fc-white fs-16",
                  {
                    "UserProfileNavigation__client-balance-flag--bg-light":
                      _vm.clientBalance === 0,
                    "UserProfileNavigation__client-balance-flag--bg-green":
                      _vm.clientBalance > 0,
                    "UserProfileNavigation__client-balance-flag--bg-red":
                      _vm.clientBalance < 0
                  }
                ],
                attrs: { "data-cy": "user-profile-navigation-balance" }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD"
                      }).format(_vm.clientBalance / 100)
                    ) +
                    "\n      "
                )
              ]
            )
          ])
        : _vm._e(),
      _vm.user.hasOwnProperty("broker")
        ? _c(
            "div",
            { staticClass: "row row--align-center row--width-auto" },
            [
              _c("base-debtor-rating", {
                attrs: {
                  rating: _vm.getDisplayRating(
                    _vm.user.rating,
                    _vm.user.external_rating
                  ),
                  size: "large"
                }
              })
            ],
            1
          )
        : _vm._e()
    ]),
    _c("div", { staticClass: "column" }, [
      _vm.user.mc || _vm.user.broker
        ? _c("label", { staticClass: "fc-light fs-15 uppercase" }, [
            _vm._v(
              "\n      MC: " +
                _vm._s(_vm.user.mc || _vm.user.broker.mc) +
                "\n    "
            )
          ])
        : _vm._e(),
      _vm.user.phone
        ? _c("label", { staticClass: "fc-light fs-15" }, [
            _vm._v(
              "\n      Phone: " +
                _vm._s(_vm.formatPhoneNumber(_vm.user.phone)) +
                "\n    "
            )
          ])
        : _vm._e()
    ]),
    !_vm.userIsClient
      ? _c(
          "div",
          { staticClass: "UserProfileNavigation__nav-items row mt-30" },
          _vm._l(_vm.routes, function(route, index) {
            return _c(
              "router-link",
              {
                key: index,
                staticClass:
                  "UserProfileNavigation__link fc-middle fs-15 mr-30 capitalize",
                attrs: {
                  to: route.to,
                  "active-class":
                    "UserProfileNavigation__link--active fc-dark fw-med",
                  "data-cy": "user-profile-nav-" + route.text + "-tab",
                  tag: "a"
                }
              },
              [_vm._v("\n      " + _vm._s(route.text) + "\n    ")]
            )
          }),
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }